exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/aboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-artist-details-tsx": () => import("./../../../src/templates/artistDetails.tsx" /* webpackChunkName: "component---src-templates-artist-details-tsx" */),
  "component---src-templates-artists-tsx": () => import("./../../../src/templates/artists.tsx" /* webpackChunkName: "component---src-templates-artists-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-exhibition-details-tsx": () => import("./../../../src/templates/exhibitionDetails.tsx" /* webpackChunkName: "component---src-templates-exhibition-details-tsx" */),
  "component---src-templates-exhibitions-tsx": () => import("./../../../src/templates/exhibitions.tsx" /* webpackChunkName: "component---src-templates-exhibitions-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-press-tsx": () => import("./../../../src/templates/press.tsx" /* webpackChunkName: "component---src-templates-press-tsx" */),
  "component---src-templates-publications-tsx": () => import("./../../../src/templates/publications.tsx" /* webpackChunkName: "component---src-templates-publications-tsx" */)
}

